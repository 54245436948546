<template>
  <div>
    <div class="search">
      <el-input placeholder="请输入站名查询" style="width: 200px; padding-right: 10px;" v-model="name"></el-input>
      <el-input placeholder="请输入站号查询" style="width: 200px; padding-right: 10px;" v-model="number"></el-input>
      <el-button type="info" plain style="margin-left: 10px" @click="load(1)">查询</el-button>
      <el-button type="warning" plain style="margin-left: 10px" @click="reset">重置</el-button>
    </div>

    <div class="operation">
      <el-button type="primary" plain @click="handleAdd">新增站点</el-button>
      <el-button type="info" @click="exportsPhoto">方位图全导出</el-button>
      <el-button type="primary" @click="sPhoto">统计方位图</el-button>
      <el-button type="danger" @click="allDelPhoto">清空全部方位图</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" strip @selection-change="handleSelectionChange" stripe border
                :header-cell-class-name="'headerBg'">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="序号" width="50" align="center"></el-table-column>
        <el-table-column prop="name" label="站名" width="180" align="center"></el-table-column>
        <el-table-column prop="shortName" label="简名" width="90" align="center"></el-table-column>
        <el-table-column prop="number" label="站号" width="80" align="center"></el-table-column>
        <el-table-column prop="address" label="详细地址" width="230" align="center"></el-table-column>
        <el-table-column prop="detailedType" label="台站类型" width="100" align="center"></el-table-column>
        <el-table-column prop="createStationTime" label="建站时间" width="120" align="center"></el-table-column>
        <el-table-column label="方位图" align="center">
          <template v-slot="scope">

            <template v-for="src in scope.row.reserved2">
              <el-image style="height: 30px; border-radius: 1%;padding-right: 10px;" fit="contain"
                        :src="src" :preview-src-list="scope.row.imgPath"></el-image>
            </template>

          </template>
        </el-table-column>
        <el-table-column label="装备图" width="80" align="center">
          <template v-slot="scope">
            <el-link @click="viewPhoto('c'+scope.row.id)"><span class="view-text">浏览图集</span></el-link>
            <template v-for="src in scope.row.reserved3">
            <el-image style="height: 30px; border-radius: 1%;padding-right: 10px;" fit="contain" hidden :id="'c'+scope.row.id"
                      :src="src" :preview-src-list="scope.row.reserved3"></el-image>
            </template>
<!--            <el-button size="mini" type="text" @click="viewP(scope.row)">浏览图集</el-button>-->
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="210">
          <template v-slot="scope">
            <el-button size="mini" type="primary" plain @click="handleEdit(scope.row)">编辑</el-button>

            <el-popover width="200" :ref="`popover-${scope.$index}`" title="按需下载">
              <div style="text-align: right; margin: 0">
                <el-button type="primary" size="mini" @click="handleDownload(scope.row)">方位图集</el-button>
                <el-button type="info" size="mini" @click="handleDownload1(scope.row)">装备图集</el-button>
              </div>
              <el-button size="mini" type="info" plain slot="reference">下载</el-button>
            </el-popover>

            <el-button size="mini" type="danger" plain @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[5, 10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="title" :visible.sync="fromVisible" width="200" :close-on-click-modal="false" destroy-on-close>
      <el-form :model="form" label-width="100px" style="padding-right: 50px" :rules="rules" ref="formRef">
        <el-row>
          <el-col :span="6">
            <el-form-item label="站号">
              <el-input v-model="form.number" :disabled="title!=='新增'"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="站名">
              <el-input v-model="form.name" :disabled="title!=='新增'"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="短命">
              <el-input v-model="form.shortName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="地址">
          <el-input v-model="form.address" :disabled="title!=='新增' && title!=='编辑'"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="经度">
              <el-input v-model="form.latitude1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="纬度">
              <el-input v-model="form.longitude1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="海拔高度">
              <el-input v-model="form.altitude"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="类型">
              <!--              <el-input v-model="form.detailedType" :disabled="title!=='新增'"></el-input>-->
              <el-select v-model="form.detailedType" placeholder="请选择">
                <el-option label="区域站" value="区域站"/>
                <el-option label="国家天气站" value="国家天气站"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="建站时间">
              <!--              <el-input v-model="form.createStationTime" :disabled="title!=='新增'"></el-input>-->
              <el-input v-model="form.createStationTime"></el-input>

            </el-form-item>
          </el-col>
        </el-row>
        <!--        <el-form-item label="方位图片">-->
        <!--          <el-upload-->
        <!--              class="avatar-uploader"-->
        <!--              :action="$baseUrl + '/stationinfo/uploadphoto'"-->
        <!--              :headers="{ token: user.token }"-->
        <!--              :show-file-list="false"-->
        <!--              :data="{ department:user.department, name: form.name, id:0 }"-->
        <!--              :on-success="handleAvatarSuccess"-->
        <!--              :before-upload="beforeAvatarUpload">-->
        <!--            <img v-if="imgPath[0]" :src="imgPath[0]" class="avatar" style="width: 100px; height: 100px">-->
        <!--            <div v-else class="el-icon-plus avatar-uploader-icon">请上传图片</div>-->
        <!--            <el-button type="primary" size="mini">上传</el-button>-->
        <!--          </el-upload>-->

        <!--          <div v-if="form.id" style="width:100%;display: flex;flex-wrap: wrap;">-->
        <!--            &lt;!&ndash; 东北0 东1 东南2 南3 西南4 西5 西北6 北7 全景8 &ndash;&gt;-->
        <!--            <div class="imgBox" style="padding: 10px; margin: 10px; background-color: #cccccc;border-radius: 5px">-->
        <!--              <el-imqge :src="imgPath[0]" style="width: 100px; height: 100px" alt="请上传方位图" fit="contain"/>-->
        <!--              <div style="display: flex;justify-content: space-between">-->
        <!--                {{ position[0] }}-->
        <!--                <el-upload :show-file-list="false"-->
        <!--                           class="avatar-uploader"-->
        <!--                           :action="$baseUrl + '/stationinfo/uploadphoto'"-->
        <!--                           :headers="{ token: user.token }"-->
        <!--                           :data="{ department:user.department, name: form.name, id:0 }"-->
        <!--                           list-type="picture"-->
        <!--                           :on-success="handleImgSuccess"-->
        <!--                >-->
        <!--                  <el-button type="primary" size="mini">上传</el-button>-->
        <!--                </el-upload>-->
        <!--              </div>-->
        <!--            </div>-->

        <!--            <div class="imgBox" style="padding: 10px; margin: 10px; background-color: #cccccc;border-radius: 5px">-->
        <!--              <img :src="imgPath[1]" style="width: 100px; height: 100px" alt="请上传方位图" fit="contain">-->
        <!--              <div style="display: flex;justify-content: space-between">-->
        <!--                {{ position[1] }}-->
        <!--                <el-upload :show-file-list="false"-->
        <!--                           class="avatar-uploader"-->
        <!--                           :action="$baseUrl + '/stationinfo/uploadphoto'"-->
        <!--                           :headers="{ token: user.token }"-->
        <!--                           :data="{ department:user.department, name: form.name, id:1 }"-->
        <!--                           list-type="picture"-->
        <!--                           :on-success="handleImgSuccess"-->
        <!--                >-->
        <!--                  <el-button type="primary" size="mini">上传</el-button>-->
        <!--                </el-upload>-->
        <!--              </div>-->
        <!--            </div>-->

        <!--            <div class="imgBox" style="padding: 10px; margin: 10px; background-color: #cccccc;border-radius: 5px">-->
        <!--              <img :src="imgPath[2]" style="width: 100px; height: 100px" alt="请上传方位图" fit="contain">-->

        <!--              <div style="display: flex;justify-content: space-between">-->
        <!--                {{ position[2] }}-->
        <!--                <el-upload :show-file-list="false"-->
        <!--                           class="avatar-uploader"-->
        <!--                           :action="$baseUrl + '/stationinfo/uploadphoto'"-->
        <!--                           :headers="{ token: user.token }"-->
        <!--                           :data="{ department:user.department, name: form.name, id:2 }"-->
        <!--                           list-type="picture"-->
        <!--                           :on-success="handleImgSuccess"-->
        <!--                >-->
        <!--                  <el-button type="primary" size="mini">上传</el-button>-->
        <!--                </el-upload>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div v-else>-->
        <!--            靓照新增好站点在添加!-->
        <!--          </div>-->
        <!--        </el-form-item>-->

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="fromVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "Stations",
  data() {
    return {
      tableData: [],  // 所有的数据
      pageNum: 1,   // 当前的页码
      pageSize: 10,  // 每页显示的个数
      total: 0,
      name: '',
      number: '',
      fromVisible: false,
      title: '编辑',  // 编辑 新增
      form: {},
      fileList: [],
      user: JSON.parse(localStorage.getItem('user') || '{}'),
      imgPath: [],  // 9个方位图，0 1 2 3 ... 8
      position: ['东北', '东', '东南', '南', '西南', '西', '西北', '北', '全景',],
      positionPhotoList: [],
      visible: false,
      equipments: [],
    }
  },
  created() {
    this.loadPosition() //调入所有方位图
    this.request.get('/equipment', {
      params: {}
    }).then(res => {
      console.log('所有装备', res.data)
      this.equipments = res.data
      this.load(1)
    })
  },
  methods: {
    viewPhoto(id){
      // 假设你的el-image有一个特定的ID或可以通过其他方式定位
      const imageElement = document.querySelector('#'+id); // 使用CSS选择器定位
      // 触发点击事件
      imageElement.click();
      console.log('ok?')
    },
    // 关闭popper
    closePopper(index){
      // this.$refs[`popover-${index}`].doClose()
      // this.$refs[ref].doClose()
      this.$refs.closepopover.click();
    },
    handleAdd() {   // 新增站点
      this.title = '新增'
      this.fileList = []
      this.form = {}  // 新增数据的时候清空数据
      this.fromVisible = true   // 打开弹窗
    },
    handleEdit(row) {   // 编辑数据
      this.title = '编辑'
      this.form = JSON.parse(JSON.stringify(row))  // 给form对象赋值  注意要深拷贝数据
      this.fileList = []
      this.imgPath = new Array(9).fill('');
      if (this.form.imgPath) {
        this.form.imgPath.map((item) => { // 站名_?.jpg 如https://www.yz-awen.cn:9090/file/stations/宜州庆远气象观测站_1.jpg
          let fileName = item.substring(item.lastIndexOf('/') + 1, item.lastIndexOf('.'));
          let number = fileName.substring(fileName.length - 1, fileName.length)
          if (fileName.substring(0, fileName.length - 1) === (this.form.name + '_')) {
            this.imgPath[number] = item
          } else {
            this.imgPath[number] = ''
          }
          this.fileList.push({name: 'test', url: item})
        })
        console.log("imgPathList:", this.imgPath)
      }
      console.log('<<>>', this.imgPath)
      this.fromVisible = true   // 打开弹窗
    },
    handleDownload(row) {//下载本站方位图、
      console.log(row.imgPath)
      // this.closePopper(row)
      if (row.imgPath.length === 0) return //没有方位图返回
      this.request.post('/position-photo/zipFile', row.imgPath).then(res => {
        console.log(res)
        if (res.code === '200') {  // 表示成功保存
          this.downloadFile(row.number)
          // const fileUrl = '/position-photo/download' + '&token=' + this.user.token; // 文件的URL地址
          // window.open(fileUrl);
        }
      })
    },
    handleDownload1(row) {//下载本站装备图集、
      console.log(row.reserved3)
      // this.closePopper(row)
      if (row.imgPath.length === 0) return //没有图返回
      this.request.post('/equipment/zipFile', row.reserved3).then(res => {
        console.log(res)
        if (res.code === '200') {  // 表示成功保存
          this.downloadFile1(row.number)
          // const fileUrl = '/position-photo/download' + '&token=' + this.user.token; // 文件的URL地址
          // window.open(fileUrl);
        }
      })
    },
    exportsPhoto() {//导出全部站点方位图
      //1. 获取站点的方位图连接
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var links = []
      console.log(this.positionPhotoList)
      this.positionPhotoList.map((item) => {
        links.push(item.imagePath)
      })
      console.log('links=', links)
      if (row.imgPath.length === 0) {

        return //没有方位图返回
      }
      this.request.post('/position-photo/zipFile', links).then(res => {
        if (res.code === '200') {  // 表示成功保存
          this.downloadFile('全部站点')
        }
      })
    },
    async downloadFile(stationIdC) {
      try {
        const a = document.createElement('a');
        a.href = process.env.VUE_APP_BASEURL + '/position-photo/download/' + stationIdC  //+ '&token=' + this.user.token
        a.download = 'fileName';
        a.click();
      } catch (error) {
        console.error('下载失败:', error);
      }
    },
    async downloadFile1(stationIdC) {
      try {
        const a = document.createElement('a');
        a.href = process.env.VUE_APP_BASEURL + '/equipment/download/' + stationIdC  //+ '&token=' + this.user.token
        a.download = 'fileName';
        a.click();
      } catch (error) {
        console.error('下载失败:', error);
      }
    },
    handleImgSuccess(response, file, fileList) {
      // 把方位图片的链接更新到本地
      let url = response.data  // url
      let fileName = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.')); // 取得文件名（不含后缀）
      let number = fileName.substring(fileName.length - 1, fileName.length)        // 取得方位序号
      this.imgPath[number] = ''
      this.imgPath[number] = url    // 填入相应方位
      console.log("上传后imgPath:", this.imgPath)
    },
    handleRemove(file, fileList) {
      console.log(file.name, file.url, file.uid, fileList);
      this.fileList = fileList;
    },
    save() {   // 保存按钮触发的逻辑  它会触发新增或者更新
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.form.imgPath = ''
          var arr = []
          this.imgPath.map((item) => {
            if (item) arr.push(item)
          })
          this.form.imgPath = ''//arr.join(',')
          this.form.reserved2 = null
          this.form.reserved3 = null
          console.log(this.form)
          this.request.post('/stationinfo', this.form).then(res => {
            if (res.code === '200') {  // 表示成功保存
              this.$message.success('保存成功')
              this.load(1)
              this.fromVisible = false
            } else {
              this.$message.error(res.msg)  // 弹出错误的信息
            }
          })
        }
      })
    },
    del(id) {   // 单个删除
      this.$confirm('您确定删除吗？', '确认删除', {type: "warning"}).then(response => {
        this.request.delete('/stationinfo/' + id).then(res => {
          if (res.code === '200') {   // 表示操作成功
            this.$message.success('操作成功')
            this.load(1)
          } else {
            this.$message.error(res.msg)  // 弹出错误的信息
          }
        })
      }).catch(() => {
      })
    },
    allDelPhoto() {
      console.log(this.user)
      if (this.user.username !== 'admin') alert('无权限操作！')
    },
    handleSelectionChange(rows) {   // 当前选中的所有的行数据
      this.ids = rows.map(v => v.id)
    },
    delBatch() {   // 批量删除
      if (!this.ids.length) {
        this.$message.warning('请选择数据')
        return
      }
      this.$confirm('您确定批量删除这些数据吗？', '确认删除', {type: "warning"}).then(response => {
        this.request.delete('/stationinfo/delete/batch', {data: this.ids}).then(res => {
          if (res.code === '200') {   // 表示操作成功
            this.$message.success('操作成功')
            this.load(1)
          } else {
            this.$message.error(res.msg)  // 弹出错误的信息
          }
        })
      }).catch(() => {
      })
    },
    loadPosition() {
      this.request.get('/position-photo', {
        params: {}
      }).then(res => {
        console.log('所有方位图', res.data)
        this.positionPhotoList = res.data
      })
    },
    load(pageNum) {  // 分页查询
      if (pageNum) this.pageNum = pageNum
      this.request.get('/stationinfo/page', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,      // 站名
          number: this.number,      // 站号
        }
      }).then(res => {
        console.log(res.data)
        var newList = []
        var newDta = res.data.records.map((item) => {
          var photoList = []
          var equipmentPhotoList = []
          var littlePhotoList = [] // 缩略图！
          this.positionPhotoList.map(position => {
            if (item.number == position.stationIdC) {
              photoList.push(position.imagePath)
//https://www.yz-awen.cn:9090/file/stations/河池市宜州区气象局-N8371_NE_2025-02-20-8131ed64e8b24e6fb352420bb722698d.jpg
              const littlePhoto = position.imagePath.substring(0, position.imagePath.lastIndexOf('/') + 1) +
                  'little-' + position.imagePath.substring(position.imagePath.lastIndexOf('/') + 1)
              console.log(littlePhoto)
              littlePhotoList.push(littlePhoto)
            }
          })
            // currentLocation
            //     :
            //     "宜州区气象局/宜州龙头国家气象观测站(地面气象观测)"
            this.equipments.map(equipment => {
              if(equipment.status==='在用') {
                var name = equipment.currentLocation
                var stationName = name.substring(name.indexOf('/') + 1, name.indexOf('('))

              if (item.name == stationName) {
                console.log('风往北吹：'+stationName)
                if(equipment.imgPath) equipmentPhotoList.push(equipment.imgPath)
//                 photoList.push(position.imagePath)
// //https://www.yz-awen.cn:9090/file/stations/河池市宜州区气象局-N8371_NE_2025-02-20-8131ed64e8b24e6fb352420bb722698d.jpg
//                 const littlePhoto = position.imagePath.substring(0, position.imagePath.lastIndexOf('/') + 1) +
//                     'little-' + position.imagePath.substring(position.imagePath.lastIndexOf('/') + 1)
//                 console.log(littlePhoto)
//                 littlePhotoList.push(littlePhoto)
              }
              }
          })
          item.imgPath = photoList
          item.reserved2 = littlePhotoList
          item.reserved3 = equipmentPhotoList
          newList.push(item)
        })
        console.log('newdat:>>>>>>>>>>>>>>>>>>>>>>', newList)
        res.data.records = newList
        // this.tableData = res.data?.records
        this.tableData = res.data?.records
        this.total = res.data?.total
      })
    },
    reset() {
      this.name = this.number = null
      this.load(1)
    },
    handleCurrentChange(pageNum) {
      this.load(pageNum)
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.load(1)
    },

    handleAvatarSuccess(response, file, fileList) {
      // 把头像属性换成上传的图片的链接
      // this.form.imgpath = response.data
      console.log("sadsadddddddddddddd>>>>>", response.data)
      this.imgPath[0] = response.data
      this.fileList.push({name: 'test', url: response.data})
    },
    handleCirculation() {//流转按钮
      this.circulationVisible = true
    },
    circulationSave() {
      this.circulationVisible = false
    },
  },
}
</script>

<style scoped>
.operation {
  padding: 10px;
}
.view-text{
  font-style: italic;
}
.view-text:hover{
  font-weight: 700;
}
</style>le>
